<template>
  <div class="table-settings" v-click-outside="closeAction">
    <div v-if="isOpen" class="table-settings__ddown box" :data-body="uniqId">
      <!-- Head -->
      <div class="table-settings__head flex ai-c jc-sb ggap-10 p-10">
        <h3 class="title">Настройки колонок</h3>
        <button class="btn sm transparent-grey" @click="toggleAll">
          <BaseIcon class="ic-16" :class="isToggleAll ? 'primary' : 'red'" :icon="isToggleAll ? 'eye' : 'eye-off'" />
          {{ isToggleAll ? 'Скрыть все' : 'Показать все' }}
        </button>
      </div>

      <!-- Body -->
      <div class="table-settings__body overflow-a">
        <Draggable v-model="cols" item-key="key" ghost-class="ghost" handle=".handle">
          <template #item="{ element }">
            <Item :col="element" />
          </template>
        </Draggable>
      </div>

      <!-- Foot -->
      <div class="table-settings__foot flex ai-c jc-sb ggap-10 p-10">
        <button class="btn sm cancel" @click="resetAction" :disabled="isNotDefault">Сбросить</button>
        <button class="btn sm primary" @click="saveAction" :disabled="isChanged">Применить</button>
      </div>
    </div>

    <button
      class="table-settings__btn btn sm transparent-primary"
      :class="{ active: isOpen }"
      type="button"
      :data-btn="uniqId"
      @click="toggleAction"
    >
      <BaseIcon class="ic-16 primary" icon="settings" />
      Настройки колонок
    </button>
  </div>
  <button class="table-settings__btn btn sm transparent-primary" type="button" @click="toggleExcelAction">
    <BaseIcon class="ic-16 primary" icon="doc-2" />
    Скачать отчет
  </button>
  <button
    class="table-settings__btn btn sm"
    :class="{ primary: archive, 'transparent-primary': !archive }"
    type="button"
    v-tippy="'Архив содержит завершенные и отклоненные заявки'"
    @click="archiveApplications"
  >
    <BaseIcon class="ic-16" icon="doc-1" :class="{ white: archive, primary: !archive }" />
    {{ archive ? 'Скрыть архивные' : 'Архив' }}
  </button>

  <div v-if="excelOpen" class="col-filter__body box grid" :data-body="uniqId" v-click-outside="toggleExcelAction">
    <BaseLoad v-if="isLoad" class="white z2" />
    <div class="flex ai-c ggap-5 p-10">
      <span>Выберите период:</span>
    </div>
    <hr class="m-0" />
    <!-- Body -->
    <div class="grid ggap-10 p-10">
      <FormDate
        showPastDays
        v-model="excelForm.start"
        type="date"
        required
        format="YYYY-MM-DD"
        label="Дата с"
        placeholder="-"
      />
      <FormDate
        :startDate="startDate"
        v-model="excelForm.end"
        type="date"
        required
        format="YYYY-MM-DD"
        label="Дата до"
        placeholder="-"
      />
    </div>
    <hr class="m-0" />
    <button class="btn grey m-10" :disabled="!(excelForm.start && excelForm.end)" @click="downloadExcelHandler()">
      Скачать
    </button>
  </div>
</template>

<script setup>
import Draggable from 'vuedraggable'
import { cloneDeep, isEqual } from 'lodash'
import { useStore } from 'vuex'
import { defineProps, computed, nextTick, ref, toRefs } from 'vue'
import { BaseIcon, FormDate } from '@/components'
import Item from './Item.vue'
import { calcCoords, $busEmit } from '@/plugins'
import ordersCols from '@/assets/json/orders-cols'
import moment from 'moment'
import orders from '@/api/modules/orders'

// Props
const props = defineProps(['storeName'])

// Data
const { storeName } = toRefs(props)
const store = useStore()
const uniqId = 'table-settings'
const cols = ref([])
const isOpen = ref(false)
const isToggleAll = ref(true)
const excelOpen = ref(false)
const excelForm = ref({ start: '', end: '' })
const filters = computed(() => store.getters['orders/filters'])
const page = computed(() => store.getters['orders/getPage'])
const archiveLocal = JSON.parse(localStorage.getItem('archiveShow')) ?? false
const archive = ref(archiveLocal)

// Computed
const colsStore = computed(() => store.getters[`${storeName.value}/tableCols`])
const startDate = computed(() => {
  return moment(excelForm.value?.start).format('YYYY-MM-DD')
})
const isNotDefault = computed(() => {
  const oldCols = cloneDeep(ordersCols)
  const newCols = cloneDeep(colsStore.value)
  return isEqual(oldCols.sort(), newCols.sort())
})
const isChanged = computed(() => {
  const oldCols = cloneDeep(colsStore.value)
  const newCols = cloneDeep(cols.value)
  return isEqual(oldCols.sort(), newCols.sort())
})

// Created
cols.value = cloneDeep(colsStore.value)

// Methods
function archiveApplications() {
  localStorage.setItem('archiveShow', !archive.value)
  $busEmit('archive', !archive.value)
  archive.value = !archive.value
}
function toggleAction() {
  isOpen.value = !isOpen.value
  calcCoords(uniqId, 'rtl')
}
function toggleExcelAction() {
  excelOpen.value = !excelOpen.value
  calcCoords(uniqId, 'ltr')
}
function closeAction() {
  isOpen.value = false
  cols.value = cloneDeep(colsStore.value)
}
function resetAction() {
  store.commit(`${storeName.value}/RESET_COLS`)
  nextTick(() => (cols.value = cloneDeep(colsStore.value)))
  closeAction()
}
function saveAction() {
  const payload = cloneDeep(cols.value)
  store.commit(`${storeName.value}/SET_COLS`, payload)
  closeAction()
}
function toggleAll() {
  isToggleAll.value = !isToggleAll.value
  cols.value.map((el) => (el.show = isToggleAll.value))
}

async function downloadExcelHandler() {
  const f = filters.value
  const start = excelForm.value.start
  const end = excelForm.value.end
  const size = 50 * page.value
  try {
    const { data } = await orders.downloadExcel(f, size, 1, start, end)
    var a = document.createElement('a')
    a.href = 'data:image/png;base64,' + data
    a.download = `Заявки с ${start} по ${end}.xlsx`
    a.click()
    excelForm.value = { start: '', end: '' }
    excelOpen.value = false
  } catch (err) {
    console.log(err)
    $busEmit('setToast', {
      type: 'red',
      icon: 'alert-triangle',
      message: 'Произошла ошибка при формировании отчета!'
    })
  }
}
</script>

<style lang="scss" scoped>
.table-settings {
  &__ddown {
    position: fixed;
    z-index: 2001;
    box-shadow: var(--box-shadow);
    width: 350px;
  }
  &__head {
    border-bottom: var(--b1);
  }
  &__body {
    max-height: calc(100vh - 350px);
  }
  &__foot {
    border-top: var(--b1);
  }
}
.col-filter {
  &__body {
    position: fixed;
    box-shadow: var(--box-shadow);
    z-index: 2002;
    width: 300px;

    &-section {
      min-height: 50px;
      max-height: calc(100vh - 350px);
    }
  }
}
</style>
