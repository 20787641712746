<template>
  <RouterView />

  <BaseToolbar>
    <div class="flex jc-sb h-100">
      <!-- Settings -->
      <div class="flex ai-c ggap-10">
        <FormSelect
          label=""
          placeholder="Не группировать"
          class="sm"
          :options="optionsGroup"
          required
          v-model="tableGrouped"
        />

        <TableSettings storeName="orders" />
      </div>

      <!-- Filter status -->
      <div class="flex ai-c ggap-10">
        <template v-if="countFilters">
          <span class="divider" />
          <button class="btn sm transparent-primary pl-10 pr-10" @click="clearFilters">
            <BaseIcon class="ic-16 red" icon="filter" />
            Сбросить фильтры
            <small class="marker primary">
              {{ countFilters }}
            </small>
          </button>
        </template>
      </div>
    </div>
  </BaseToolbar>

  <div class="content pos-r z1 overflow-a default-mains">
    <TableWrapper class="max-content" :cols="cols" :rows="rows" />
    <BaseLoad v-if="isLoad" class="grid jc-c bg fixed z3" />

    <TableNative
      :cols="cols"
      :rows="rows"
      :isOpenAll="isOpenAll"
      :visited="visitedId"
      :groupedRows="groupedRows"
      :isGrouped="!!tableGrouped"
      @toggleAll="toggleAll"
    >
      <!-- thead -->
      <template #th="{ col }">
        <div class="flex ai-c ggap-5">
          <!-- Filter -->
          <Filters v-if="col.filter" :col="col" @getData="getRows" />

          <!-- Sort -->
          <button
            v-if="col.sort"
            class="btn transparent-grey xsm pl-5 pr-5"
            :class="{
              active: tableSort.key === col.key,
              rotate: tableSort.key === col.key && tableSort.direction === 'asc'
            }"
            type="button"
            v-tippy="'Сортировка'"
            @click="setSort(col.key)"
          >
            <BaseIcon class="ic-14 grey" icon="sort" />
          </button>
        </div>
      </template>

      <!-- tbody -->
      <template #td="{ col, row }">
        <Cell :col="col" :row="row" />
      </template>
    </TableNative>
    <BaseLoad class="pos-s" v-if="isLoadMore" />
    <div class="send-to-approve" v-if="selectedRows.length && !isLoad" @click="approveAll()">Согласовать</div>
    <div
      class="send-to-decline"
      v-if="selectedRows.length === 1 && isHeadFinancier && !isLoad"
      @click="isOpenModalCancel = true"
    >
      Отказать
    </div>
  </div>

  <ModalEmpty v-if="isOpenModalCancel" @close="isOpenModalCancel = false">
    <form id="form-cancel" class="modal-cancel grid ggap-20 p-30 pos-r w-40vw" @submit.prevent="rejectHandler()">
      <BaseLoad class="loader" v-if="rejectLoader" />
      <h1 class="title">Опишите причину отклонения</h1>
      <FormEditor
        label=""
        placeholder="Описание причины отклонения, например: не достаточно информации..."
        class="big"
        required
        v-model="reasonMessage"
      />
      <div class="flex ai-c jc-sb ggap-50">
        <button class="btn cancel" type="button" @click.prevent="isOpenModalCancel = false">Отмена</button>
        <button class="btn primary" type="submit" :disabled="!reasonMessage.trim().length">
          Отправить комментарий, и отклонить заявку
        </button>
      </div>
    </form>
  </ModalEmpty>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, watchEffect, onMounted, watch } from 'vue'
import { BaseIcon, BaseLoad, BaseToolbar, TableNative, FormSelect, ModalEmpty, FormEditor } from '@/components'
import TableSettings from './components/table-settings/Index.vue'
import Cell from './components/table/Cell.vue'
import Filters from './components/table/Filters.vue'
import { $busEmit, $busOn } from '../../plugins'
import { approveAction } from './components/approve'
import orders from '../../api/modules/orders'
import socketConnect from '@/plugins/socket-connect'

// Data
const openAllRows = JSON.parse(localStorage.getItem('openAllRow'))
const store = useStore()
const requestCount = ref(0)
const isLoad = ref(true)
const isOpenAll = ref(openAllRows)
const filterStatus = ref('')
const groupedRows = ref([])
const reasonMessage = ref('')
const isLoadMore = ref(false)
const isOpenModalCancel = ref(false)
const rejectLoader = ref(false)

const optionsGroup = [
  {
    text: 'Группировать по проекту',
    value: 'company'
  },
  {
    text: 'Группировать по инициатору',
    value: 'author'
  },
  {
    text: 'Группировать по контрагенту',
    value: 'counterparty'
  },
  {
    text: 'Группировать по статусу',
    value: 'status'
  }
]

// Created
store.dispatch('helpers/GET_STATUSES')
store.dispatch('GET_PROJECTS')
const profile = computed(() => store.getters.profile)
getRows()
if (localStorage.getItem('choco_grouped')) {
  store.commit('orders/SET_TABLE_GROUPED_BY', localStorage.getItem('choco_grouped'))
}
wsConnect()
onMounted(() => {
  const el = document.querySelector('.default-mains')
  el.addEventListener('scroll', async function () {
    if (el.scrollTop > el.scrollHeight - 1000) await getNextRows()
  })
})

const page = computed(() => store.getters['orders/getPage'])
const pageCount = computed(() => store.getters['orders/getPageCount'])
const tableSort = computed(() => store.getters['orders/tableSort'])
const selectedRows = computed(() => store.getters['orders/selectedRows'] || [])
const visitedId = computed(() => store.getters['orders/item']?.id || 0)
const countFilters = computed(() => Object.keys(store.getters['orders/filters']).length)
const colsStore = computed(() => store.getters['orders/tableCols'])
const isHeadFinancier = computed(() => store.getters['isHeadFinancier'])
const tableGrouped = computed({
  get: () => store.getters['orders/tableGrouped'],
  set: (val) => store.commit('orders/SET_TABLE_GROUPED_BY', val)
})

const cols = computed(() => {
  return colsStore.value.map((item) => {
    if (tableGrouped.value && item.key === tableGrouped.value) {
      item.disabled = true
    } else {
      item.disabled = false
    }
    return item
  })
})
const rows = computed(() => {
  const res = store.getters['orders/rows']?.length ? store.getters['orders/rows'] : []
  return filterStatus.value ? res.filter((item) => item.status.uid === filterStatus.value) : res
})

// Watch
watch(tableGrouped, () => {
  localStorage.setItem('choco_grouped', tableGrouped.value)
  store.commit('orders/SET_TABLE_GROUPED_BY', tableGrouped.value)
  getRows()
})
$busOn('archive', () => {
  clearFilters()
  getRows()
})
watchEffect(() => {
  if (tableGrouped.value && rows.value?.length) groupedBy()
})

// Methods
function clearFilters() {
  store.commit('orders/CLEAR_FILTERS')
  getRows()
}

function getNextRows() {
  if (page.value < pageCount.value && !isLoadMore.value) {
    isLoadMore.value = true
    store.dispatch('orders/GET_MORE_ROWS').finally(() => {
      isLoadMore.value = false
    })
  }
}

function setSort(key) {
  store.commit('orders/SET_SORT', key)
  getRows()
}

async function rejectHandler() {
  const id = selectedRows.value[0]
  rejectLoader.value = true
  approveAction(id, false, reasonMessage.value)
    .then(() => {
      orders.get(id).then((row) => {
        store.commit('orders/UPDATE_ROW', row.data.data)
        $busEmit('setToast', {
          type: 'green',
          icon: 'check-circle',
          message: 'Заявки была успешно отклонена!'
        })
        store.commit('orders/CLEAR_SELECTED_ROWS')
        reasonMessage.value = ''
      })
    })
    .catch((err) => {
      console.log(err)
      $busEmit('setToast', {
        type: 'red',
        icon: 'close-circle',
        message: 'При согласовании произошла ошибка, обратитесь к Администратору!'
      })
    })
    .finally(() => {
      rejectLoader.value = false
      isOpenModalCancel.value = false
    })
}

function approveAll() {
  isLoad.value = true
  const payload = { ids: selectedRows.value }
  orders
    .approveAll(payload)
    .then((res) => {
      store.commit('orders/UPDATE_ROWS', res.data)
      store.commit('orders/CLEAR_SELECTED_ROWS')
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        message: `Заявки были успешно согласованы!`
      })
      isLoad.value = false
    })
    .catch((err) => {
      console.log(err)
      $busEmit('setToast', {
        type: 'red',
        icon: 'close-circle',
        message: 'При согласовании произошла ошибка, обратитесь к Администратору!'
      })
    })
}

function getRows() {
  isLoad.value = true
  store.dispatch('orders/GET_TYPES')
  store.dispatch('orders/GET_ROWS').finally(() => {
    requestCount.value = requestCount.value + 1
    isLoad.value = false
  })
}

function getUniqArray(items) {
  const uniqArray = []
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const index = uniqArray.findIndex((el) => el?.id === item[tableGrouped.value]?.id)
    if (index === -1) uniqArray.push(item[tableGrouped.value])
  }
  return uniqArray
}

function groupedBy() {
  groupedRows.value.splice(0)

  // Собираем все уникальные ключи
  const uniqItems = rows.value?.length ? getUniqArray(rows.value) : []

  // Формируем массив
  for (let uniqItem of uniqItems) {
    const groupedRowsLocal = JSON.parse(localStorage.getItem('grouped-rows'))
    if (uniqItem?.id) {
      const groupedValue = uniqItem?.project || uniqItem?.name || uniqItem?.fullname || uniqItem?.company_name || ''
      const val = groupedRowsLocal?.[tableGrouped.value]?.find((el) => el.key === groupedValue)
      groupedRows.value.push({
        id: uniqItem?.id || 0,
        groupedItem: uniqItem,
        groupedKey: tableGrouped.value,
        groupedValue: uniqItem?.project || uniqItem?.name || uniqItem?.fullname || uniqItem?.company_name || '',
        opened: val?.opened ?? true,
        subrows: rows.value.filter((row) => row[tableGrouped.value]?.id === uniqItem?.id)
      })
    } else {
      groupedRows.value.unshift({
        id: 0,
        groupedItem: uniqItem,
        groupedKey: tableGrouped.value,
        groupedValue: '',
        opened: false,
        subrows: rows.value.filter((row) => row[tableGrouped.value]?.id === uniqItem?.id)
      })
    }
  }
}

function toggleAll() {
  isOpenAll.value = !isOpenAll.value
  localStorage.setItem('openAllRow', isOpenAll.value)
  groupedRows.value.forEach((item) => {
    toggleGroupOpened(item)
    item.opened = isOpenAll.value
  })
}

function toggleGroupOpened(group) {
  const groupedRowsLocal = JSON.parse(localStorage.getItem('grouped-rows')) || {}
  const index = groupedRowsLocal?.[group.groupedKey]?.findIndex((el) => el.key === group.groupedValue)
  if (index > -1) {
    groupedRowsLocal[group.groupedKey][index] = { key: group.groupedValue, opened: !group.opened }
  } else {
    if (!groupedRowsLocal[group.groupedKey]?.length) groupedRowsLocal[group.groupedKey] = []
    groupedRowsLocal[group.groupedKey].push({ key: group.groupedValue, opened: !group.opened })
  }
  group.opened = !group.opened
  localStorage.setItem('grouped-rows', JSON.stringify(groupedRowsLocal))
}

function addOrUpdateItem(id, flag) {
  orders.get(id).then((res) => {
    const action = flag === 'update' ? 'orders/UPDATE_ROW' : 'orders/ADD_ROW'
    store.commit(action, res.data.data)
  })
}

// websocket
function wsConnect() {
  const user_id = profile.value?.id
  socketConnect
    .channel(`private.financier.application.${user_id}`)
    .listen('.NewFinancierApplicationBroadcast', (res) => {
      res.application_id && addOrUpdateItem(res.application_id, 'add')
    })

  socketConnect
    .channel(`financier.application.status.${user_id}`)
    .listen('.FinancierApplicationListStatusChangedBroadcast', (res) => {
      res?.application_id && addOrUpdateItem(res.application_id, 'update')
    })
}
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: rgb(72, 72, 72);
  border-radius: 10px;
}
.send-to-approve {
  position: fixed;
  left: 30px;
  bottom: 20px;
  user-select: none;
  background-color: var(--primary);
  padding: 10px 20px;
  color: white;
  border-radius: 50px;
  cursor: pointer;
}
.send-to-decline {
  position: fixed;
  left: 160px;
  bottom: 20px;
  background-color: var(--red);
  padding: 10px 20px;
  user-select: none;
  color: white;
  border-radius: 50px;
  cursor: pointer;
}
</style>
