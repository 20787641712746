<template>
  <!-- Status -->
  <template v-if="col.key === 'status'">
    <div class="igrid cut">
      <small
        v-if="row[col.key]"
        v-tippy="row[col.key].name"
        class="marker cut"
        :style="{
          color: row[col.key].text_color,
          backgroundColor: row[col.key].background_color
        }"
      >
        {{ row[col.key].name }}
      </small>
      <template v-else>-</template>
    </div>
  </template>

  <!-- Can approve -->
  <template v-else-if="col.key === 'can_approve'">
    <div class="flex ai-c ggap-5" @click="addToCheck(row)">
      <span>
        <input v-if="row.can_approve" type="checkbox" :checked="selectedRows.includes(row.id)" />
        <span v-else>&mdash;</span>
      </span>
    </div>
  </template>

  <!-- Cost -->
  <template v-else-if="col.key === 'cost'">
    <div class="igrid cut ta-right w-100" v-tippy="separator(row[col.key])">
      <div v-if="row[col.key]" class="cut t-upper">{{ separator(row[col.key]) }}</div>
      <template v-else>-</template>
    </div>
  </template>

  <!-- Currency -->
  <template v-else-if="col.key === 'currency'">
    <div class="igrid cut">
      <span v-if="row[col.key]" class="cut t-upper"> {{ row[col.key] }} </span>
      <template v-else>-</template>
    </div>
  </template>

  <!-- Type -->
  <template v-else-if="col.key === 'type'">
    <div class="igrid cut" v-tippy="getType(row[col.key])">
      <span class="cut">
        <span v-if="row[col.key]" class="cut"> {{ getType(row[col.key]) }} </span>
      </span>
    </div>
  </template>

  <!-- Author -->
  <template v-else-if="col.key === 'author'">
    <div class="igrid cut" v-tippy="!isAuthor && row[col.key].fullname">
      <small v-if="isAuthor" class="marker primary cut"> Вы </small>
      <span v-else class="cut">
        {{ row[col.key]?.fullname }}
      </span>
    </div>
  </template>

  <!-- Project -->
  <template v-else-if="col.key === 'company'">
    <div class="igrid cut" v-tippy="row[col.key]?.project">
      <span class="cut">
        {{ row[col.key] ? row[col.key].project : '-' }}
      </span>
    </div>
  </template>

  <!-- Project -->
  <template v-else-if="col.key === 'pay_date_planned' || col.key === 'pay_date'">
    <div class="igrid cut" v-tippy="row[col.key]">
      <span class="cut">
        {{ row[col.key] ? moment(row[col.key]).format('DD-MM-YYYY') : '-' }}
      </span>
    </div>
  </template>

  <!-- Counterparty -->
  <template v-else-if="col.key === 'counterparty'">
    <div class="igrid cut" v-tippy="row?.counterparty?.company_name">
      <span class="cut">
        {{ row.counterparty ? row.counterparty.company_name : '-' }}
      </span>
    </div>
  </template>

  <!-- Created && Updated -->
  <template v-else-if="col.key === 'created_at' || col.key === 'updated'">
    <div class="igrid cut" v-tippy="formatRuDateTime(row[col.key])">
      <span class="cut">
        {{ row[col.key] ? formatRuDateTime(row[col.key]) : '-' }}
      </span>
    </div>
  </template>

  <!-- Other -->
  <template v-else>
    <div class="igrid cut" v-tippy="row[col.key]">
      <span class="cut">
        {{ row[col.key] ? row[col.key] : '-' }}
      </span>
    </div>
  </template>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, defineProps, toRefs } from 'vue'
import { formatRuDateTime } from '@/plugins'
import moment from 'moment'

// Props
const props = defineProps({
  col: {
    type: Object,
    default: () => ({})
  },
  row: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { col, row } = toRefs(props)
const store = useStore()

// Computed
const types = computed(() => store.getters['orders/optionsPayType'])
const selectedRows = computed(() => store.getters['orders/selectedRows'] || [])
const profile = computed(() => store.getters.profile)
const isAuthor = computed(() => row.value?.author?.id === profile.value.id)

// Methods
const addToCheck = (row) => row?.can_approve && store.commit('orders/SELECT_ROW', row?.id)
function separator(n) {
  var parts = n?.toFixed(2).toString()?.split('.')
  if (parts) {
    const numberPart = parts[0]
    const decimalPart = parts[1]
    const thousands = /\B(?=(\d{3})+(?!\d))/g
    return numberPart.replace(thousands, ' ') + (decimalPart ? '.' + decimalPart : '')
  } else {
    return n
  }
}

function getType(val) {
  return types.value?.find((i) => i.value === val)?.text
}
</script>

<style></style>
