<template>
  <div class="col-filter" v-click-outside="closeAction">
    <button
      class="btn xsm pl-5 pr-5"
      :class="{ active: filters, outline: isOpen, 'transparent-grey': !isOpen }"
      v-tippy="'Фильтр по колонке'"
      :data-btn="uniqId"
      @click="toggleAction"
    >
      <BaseIcon class="ic-14" :class="isOpen ? 'primary' : 'grey'" icon="filter" />
    </button>

    <!-- Drop down -->
    <div v-if="isOpen" class="col-filter__body box grid fs-14" :data-body="uniqId">
      <BaseLoad v-if="isLoad" class="white z2" />
      <div class="flex ai-c ggap-5 p-10">
        <span class="fw-n">Фильтр по колонке:</span>
        <h4 class="title">{{ col.name }}</h4>
      </div>
      <hr class="m-0" />

      <!-- Search -->
      <div v-if="col.key === 'author'" class="col-filter__body-section search p-10 grid gtc-1-auto ai-c ggap-5">
        <div class="grid pos-r">
          <FormAutocompleteSearch
            class="f-1"
            placeholder="Выберите исполнителя"
            :options="optionsUsers"
            :isLoad="isUserLoad"
            :isMultiple="false"
            :name="col.key"
            :required="required"
            @search="searchUsers($event)"
            v-model="filters"
          >
            <template #single="{ item }">
              <div v-if="item && item.id" class="grid gtc-auto-1 ggap-10">
                <div class="cover ava-24">
                  <img :src="item.avatar || require('@/assets/img/ava.svg')" />
                </div>
                <div class="grid gtc-auto-1 ai-c ggap-10">
                  <div class="cut">{{ item.text }}</div>
                </div>
              </div>
              <div v-else class="t-grey-dark">Выберите пользователя</div>
            </template>
          </FormAutocompleteSearch>
        </div>
        <button type="button" class="btn grey" @click.prevent="assignMe">Моё</button>
      </div>

      <!-- Status -->
      <div
        v-if="col.key === 'status'"
        class="col-filter__body-section check-list statuses flex fw ggap-5 overflow-a p-10"
      >
        <button
          v-for="el of optionsStatuses"
          :key="el.id"
          class="statuses__tag flex ai-c ggap-5"
          type="button"
          @click="setStatus(el.id)"
        >
          <div class="check-list__item box flex ai-c jc-c" :class="{ active: isActiveStatus(el.id) }">
            <BaseIcon class="ic-14 grey" icon="check" />
          </div>
          <small class="marker" :style="{ color: el.text_color, backgroundColor: el.background_color }">
            {{ el.name }}
          </small>
        </button>
      </div>

      <!-- Project -->
      <div v-if="col.key === 'company'" class="col-filter__body-section check-list projects grid overflow-a">
        <button
          v-for="el of optionsProjects"
          :key="el.id"
          class="projects__tag flex ai-c ggap-10 w-100 cut"
          type="button"
          @click="setProject(el.id)"
        >
          <div class="check-list__item box flex ai-c jc-c" :class="{ active: isActiveProject(el.id) }">
            <BaseIcon class="ic-14 grey" icon="check" />
          </div>
          <span class="cut">
            <b>{{ el.text || 'Нет имени' }}</b> - {{ el.item.llp }}
          </span>
        </button>
      </div>

      <!-- Counterparty -->
      <div v-if="col.key === 'counterparty'" class="col-filter__body-section check-list counterparty grid overflow-a">
        <div class="p-10">
          <FormAutocompleteCounterparty
            class="f-1"
            placeholder="Выберите"
            :options="optionsCounterparties"
            :isLoad="isCounterpartyLoad"
            :isMultiple="false"
            :name="col.key"
            :required="required"
            @search="searchCounterparties($event)"
            v-model="filters"
          >
            <template #single="{ item }">
              <div v-if="item && item.id" class="grid gtc-auto-1 ggap-10">
                <div class="grid gtc-auto-1 ai-c ggap-10">
                  <div class="cut">{{ item.text }}</div>
                </div>
              </div>
              <div v-else class="t-grey-dark">-</div>
            </template>
          </FormAutocompleteCounterparty>
        </div>
      </div>

      <!-- Type -->
      <div v-if="col.key === 'type'" class="col-filter__body-section grid radio-list">
        <!-- <input type="radio" name="all" id="all" value="" v-model="filters" />
            <label for="all">Все типы</label> -->
        <template v-for="item in optionsPayType" :key="item.value">
          <input
            type="radio"
            :name="item.value"
            :id="item.value"
            :value="item.value"
            v-model="filters"
            v-show="false"
          />
          <label :for="item.value" class="flex ai-c ggap-10 p-10" :class="{ active: filters === item.value }">
            <div class="check-list__item box flex ai-c jc-c" :class="{ active: filters === item.value }">
              <BaseIcon class="ic-14 grey" icon="dot" />
            </div>
            {{ item.text }}
          </label>
        </template>
      </div>

      <!-- Date -->
      <div v-if="col.key === 'pay_date_planned' || col.key === 'pay_date'" class="p-10">
        <FormDate v-model="filters" :name="col.key" :id="col.key" :showPastDays="true" />
      </div>

      <!-- Sum -->
      <div v-if="col.key === 'cost'" class="p-10">
        <FormSumm v-model="filters" :showcurrency="false" type="number" separator :name="col.key" :id="col.key" />
      </div>

      <!-- Currency -->
      <div v-if="col.key === 'currency'" class="col-filter__body-section check-list statuses flex fw ggap-5 p-10">
        <template v-for="item in optionsCurrencies" :key="item.id">
          <input
            type="radio"
            :name="item.value"
            :id="item.value"
            :value="item.value"
            v-model="filters"
            v-show="false"
          />
          <label :for="item.value" class="flex ai-c ggap-10 p-10" :class="{ active: filters === item.value }">
            <div class="check-list__item box flex ai-c jc-c" :class="{ active: filters === item.value }">
              <BaseIcon class="ic-14 grey" icon="dot" />
            </div>
            {{ item.text }}
          </label>
        </template>
      </div>

      <div class="p-10 grid">
        <button type="button" class="btn primary sm jc-c" :disabled="!filters" @click.prevent="setFilter">
          Применить
        </button>
        <br />
        <button type="button" class="btn cancel sm jc-c" :disabled="!filters" @click.prevent="resetFilter">
          Сбросить
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineProps, defineEmits, toRefs, computed, ref, watch, reactive, watchEffect } from 'vue'
import {
  BaseLoad,
  BaseIcon,
  FormAutocompleteSearch,
  FormDate,
  FormSumm,
  FormAutocompleteCounterparty
} from '@/components'
import { calcCoords, $busOn } from '@/plugins'

// Props
const props = defineProps(['col'])
const emits = defineEmits('getData')

// Data
const { col } = toRefs(props)
const optionsUsers = computed(() => store.getters.users || [])
const store = useStore()
const uniqId = 'filter-' + col.value.key
const isLoad = ref(false)
const isUserLoad = ref(false)
const isCounterpartyLoad = ref(false)
const isOpen = ref(false)
const archiveLocal = JSON.parse(localStorage.getItem('archiveShow')) ?? false
const archive = ref(archiveLocal)
const formData = reactive({
  status: [],
  project: [],
  counterparty: [],
  currency: []
})

// Computed
const profile = computed(() => store.getters.profile)
const allStatuses = computed(() => store.getters['helpers/statuses'] || [])
const optionsStatuses = computed(() =>
  allStatuses.value.filter((el) => (archive.value ? [8, 9].includes(el.id) : ![1, 8, 9].includes(el.id)))
)
const optionsProjects = computed(() => store.getters.projects || [])
const optionsCounterparties = computed(() => store.getters.counterparties || [])
const optionsPayType = computed(() => store.getters['orders/optionsPayType'])
const optionsCurrencies = [
  {
    text: 'Евро',
    value: 'eur',
    id: 1
  },
  {
    text: 'Американский доллар',
    value: 'usd',
    id: 2
  },
  {
    text: 'Российский рубль',
    value: 'rub',
    id: 3
  },
  {
    text: 'Тенге',
    value: 'kzt',
    id: 4
  }
]
const filters = computed({
  get: () => store.getters['orders/filters'][col.value.key],
  set: (val) => store.commit('orders/SET_FILTERS', { key: col.value.key, value: val })
})

// Watch
$busOn('archive', (val) => (archive.value = val))
watch(isOpen, async (val) => {
  if (val && col.value.key === 'status' && !optionsStatuses.value?.length) {
    isLoad.value = true
    await store.dispatch('helpers/GET_STATUSES')
    calcCoords(uniqId, 'ltr')
    isLoad.value = false
  }
  if (val && col.value.key === 'company' && !optionsProjects.value?.length) {
    isLoad.value = true
    await store.dispatch('GET_PROJECTS')
    calcCoords(uniqId, 'ltr')
    isLoad.value = false
  }
})
watchEffect(() => {
  if (col.value.key === 'status') {
    formData.status = filters.value?.length ? filters.value : []
  }
  if (col.value.key === 'project') {
    formData.project = filters.value?.length ? filters.value : []
  }
  if (col.value.key === 'counterparty') {
    formData.counterparty = filters.value?.length ? filters.value : []
  }
})

// Methods
async function searchUsers(searchText) {
  isUserLoad.value = true
  await store.dispatch('GET_USERS', searchText)
  isUserLoad.value = false
}
async function toggleAction() {
  isOpen.value = !isOpen.value
  calcCoords(uniqId, 'ltr')
}
function setFilter() {
  isOpen.value = false
  emits('getData')
  store.commit('SET_PAGE', 1)
  const el = document.querySelector('.default-mains')
  el.scrollTop = 0
}
function resetFilter() {
  filters.value = ''
  isOpen.value = false
  emits('getData')
}
function closeAction() {
  isOpen.value = false
}
function assignMe() {
  filters.value = {
    text: profile?.value?.fullname,
    avatar: profile?.value?.avatar,
    id: profile?.value?.id
  }
}
// Methods:status
function setStatus(s) {
  const index = formData.status.findIndex((el) => el === s)
  if (index === -1) formData.status.push(s)
  else formData.status.splice(index, 1)

  filters.value = formData.status?.length ? formData.status : ''
}
const isActiveStatus = (id) => formData.status.findIndex((el) => el === id) !== -1

// Methods:project
function setProject(id) {
  const index = formData.project.findIndex((el) => el === id)
  if (index === -1) formData.project.push(id)
  else formData.project.splice(index, 1)

  filters.value = formData.project?.length ? formData.project : ''
}
const isActiveProject = (id) => formData.project.findIndex((el) => el === id) !== -1

// Methods:counterparty
async function searchCounterparties(searchText) {
  if (searchText.length >= 3) {
    isCounterpartyLoad.value = true
    await store.dispatch('GET_COUNTERPARTIES_SEARCH', { searchText })
    isCounterpartyLoad.value = false
  } else if (!searchText) {
    isCounterpartyLoad.value = true
    await store.dispatch('GET_COUNTERPARTIES')
    isCounterpartyLoad.value = false
  }
}
</script>

<style lang="scss">
.rotate {
  transform: rotate(180deg);
}

.col-filter {
  &__body {
    position: fixed;
    box-shadow: var(--box-shadow);
    z-index: 2002;
    width: 400px;

    &-section {
      min-height: 50px;
      max-height: calc(100vh - 350px);
    }
  }

  .search {
    &__label {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 40px;
    }
  }

  .check-list {
    &__item {
      border-radius: 100px;
      padding: 2px;

      &.active {
        background-color: var(--primary);
        border-color: var(--primary);

        svg {
          fill: #fff;
        }
      }
    }
  }

  .radio-list {
    label:hover {
      color: var(--text);
      background-color: var(--grey-l);
      cursor: pointer;
    }

    input:checked + label {
      color: var(--text);
    }
  }

  .statuses {
    &__tag {
      padding: 5px;
      background-color: transparent;
      border: 0;
      border-radius: var(--br);
      cursor: pointer;

      &:hover {
        background-color: var(--grey-l);
      }
    }
  }

  .projects {
    &__tag {
      padding: 10px;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      height: max-content;

      &:hover {
        background-color: var(--grey-l);
      }
    }
  }
}
</style>
